@import 'variables';
@import 'mixins';

/* =============== DEFINICIONES GLOBALES =============== */
html {
    /* Evitar que un padding o border cambie el tamaño del elemento */
    @include box-sizing(border-box);
    height: 100vh;
    width: 100%;
}

*,
*:after,
*:before {
    @include box-sizing(inherit);
}

body {
    font-family: $content_font;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

img {
    max-width: 100%;
}

h1 p.editable,
h2 p.editable,
h3 p.editable,
h4 p.editable,
h5 p.editable {
    margin: 0;
}

.container {
    margin: 0 auto;
    width: 80%;
}

.section-upper-text {
    text-align: center;
    font-size: 1.3rem;
    line-height: 1.7rem;
}
/* =============== FIN DE DEFINICIONES GLOBALES =============== */

/* =============== BARRA SUPERIOR Y CARRUSEL =============== */
.navbar {
    width: 100%;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-family: $title_font;
    // color: #ffffff;
    background-color: #FFFFFF;
    padding: 0 0.75rem;
    z-index: 10;
    width: 100%;

    a {
        color: inherit;
        font-size: 1rem;
        text-decoration: none;

        @include tablet {
            font-size: 1.3rem;
        }
    }
}

.navbar-links {
    padding: 0.5rem;
    display: none;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    animation-name: menu_appear;
    animation-duration: 0.1s;
    animation-timing-function: ease-in;

    @include tablet {
        display: flex;
        flex-wrap: nowrap;
    }

    .nav-link {
        padding: 0.75rem 0;
        flex: 1 1 45%;
        text-align: center;

        @include tablet {
            flex: 0 1 content; /* Safari does not accept content */
            margin-left: 2rem;
            padding: 0.3rem 0;
            // text-align: left; Commented to be used in Safari
        }

        &:first-of-type {
            margin-left: 0;
        }
    }

    a {
        width: fit-content;
        text-decoration: none;
        font-size: 1.3rem;
        color: $secondary;
    }

    @keyframes menu_appear {
        from {
            height: 0;
            color: transparent;
        }
        to {
            height: 116px;
            color: inherit;
        }
    }
    @keyframes menu_disappear {
        from {
            height: 116px;
            color: inherit;
        }
        to {
            height: 0;
            color: transparent;
        }
    }
}

.carousel-container {
    position: relative;
    top: 0;
    max-height: 600px;
}

.site-identity {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo-text {
        font-size: 1.4rem;
        text-align: center;
        font-family: $title_font;
        // font-weight: 600;
        text-transform: none;
        font-style: normal;
        color: $secondary;
        padding: 0 0.3rem;
        /* Text shadow instead of text-stroke since it does not work well in Chrome */
        // text-shadow:
        // -1px -1px 0 ivory,
        //  1px -1px 0 ivory,
        // -1px  1px 0 ivory,
        //  1px  1px 0 ivory;

        @include tablet {
            font-size: 2rem;
        }

        @include desktop {
            font-size: 3rem;
        }
    }

    .site-name {
        font-size: 2.2rem;
        font-weight: 600;
        color: $secondary;
        padding-top: 1rem;
        /* Text shadow instead of text-stroke since it does not work well in Chrome */
        text-shadow:
        2.5px 2.5px 0 $tertiary;
        //  1px -1px 0 $tertiary,
        // -1px  1px 0 $tertiary,
        //  1px  1px 0 $tertiary;

        @include tablet {
            font-size: 4rem;
        }
    }
}

.logo {
    flex-grow: 0;
    flex-shrink: 1;
    margin: 0.3rem;
    align-self: center;

    img {
        height: 15vw;
        max-height: 130px;
        // border: 5px solid $tertiary;

        &#imagen-logo-derecha {
           display: none;

           @include tablet {
               display: block;
           }

           @include desktop {
            margin-right: 7vw;
            }
        }

        &#imagen-logo-izquierda {
            @include desktop {
                margin-left: 7vw;
            }
        }
    }
}

.menu-burger-container {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    align-self: center;
    justify-content: flex-end;

    @include tablet {
        display: none;
    }
}

.burger-menu {
    background-color: $primary;
    width: 37px;
    height: 45px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    @include tablet {
        display: none;
    }

    .burger-line {
        background-color: #FFFFFF;
        width: 60%;
        height: 7px;
        border-radius: 4px;
    }
}

.carousel img {
    min-height: 330px;
    object-fit: cover;
    object-position: 30% 30%;

    @include tablet {
        height: 580px;
    }

    @include desktop {
        width: 1080px!important;
        height: 650px;
        margin-left: calc(50vw - 540px);
    }
}

.carousel-appointment {
    @extend .carousel;

    img {
        object-position: 0% 50%;
    }
}

.dot {
    cursor: pointer;
    position: relative;
    top: -30px;
    height: 15px;
    width: 15px;
    margin: 0 5px;
    background-color: #eee;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

/* Fading animation */
.fade {
    animation-name: fade;
    animation-duration: 5s;
}

@keyframes fade {
    from {
        opacity: 0.4;
    }
    to {
        opacity: 0.9;
    }
}

/* =============== FIN DE BARRA SUPERIOR Y CARRUSEL =============== */

/* =============== BIOGRAFÍA =============== */
.biography {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 5rem;

    @include tablet {
        flex-direction: row;
    }

    @include desktop {
        // Increase margin-top
        margin-top: 7rem;
    }
}

.biography-second {
    @extend .biography;

    margin-top: 3rem;
    flex-direction:column-reverse;

    @include tablet {
        flex-direction: row;
    }

    .biography-text {
        @extend .biography-text;

        @include tablet {
            padding-right: 2rem;
        }
    }
}

.biography-image {
    flex: 1 1 40%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 2rem;

    img {
        max-height: 400px;
        object-fit: contain;
        box-shadow: 20px 20px 0px -3px #CAECEF;

        @include tablet {
            max-height: 600px;
        }
    }
}

.biography-text {
    flex: 1 1 60%;
    font-size: 1.3rem;
    line-height: 1.7rem;

    @include tablet {
        padding-left: 2rem;
    }

    p {
        margin: 0;
    }

    strong {
        color: $secondary;
    }
}
/* =============== FIN DE BIOGRAFÍA =============== */

/* =============== PRODUCTOS ORBS =============== */
.products {
    margin-top: 4rem;
}

.products-materials {
    @extend .products;

    @include desktop {
        margin-top: 7rem;
    }
}

.section-title {
    margin: 4rem 0 1.5rem 0;

    h2 {
        width: 100%;
        margin: 1rem auto;
        text-align: center;
        font-family: $title_font;
        font-weight: 700;
        color: $secondary;
        font-size: 3.5rem;

        @include desktop{
            font-size: 4.5rem;
        }
    }
}

.filigrana {
    max-width: 100%;
    object-fit: scale-down;

    @include tablet {
        max-width: 40%;
    }
}

.products-orbs {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet {
        flex-direction: row;
        justify-content: space-around;
    }
}

.product-orb {
    flex: 1 1 30%;
    text-align: center;
    max-width: 280px;
    max-height: 280px;
    margin: 1rem;
    border-radius: 10%;
    transition: all ease 0.3s;

    @include tablet {
        max-width: 300px;
        max-height: 300px;
    }

    &:hover {
        transform: scale(1.1);
    }

    img {
        position: relative;
        max-height: 100%;
        border-radius: 10%;
        z-index: -100;
    }

    a {
        position: relative;
        display: block;
        max-height: 100%;
        color: #ffffff;
        text-shadow:
        -1px -1px 0 $primary,
         1px -1px 0 $primary,
        -1px  1px 0 $primary,
         1px  1px 0 $primary;
        font-family: $title_font;
        font-size: 2.7rem;
        font-weight: 600;

        @include tablet {
            font-size: 2.9rem;
        }

        @include desktop {
            font-size: 3.8rem;
        }

        p {
            position: absolute;
            margin: 0;
            bottom: 0%;
            left: 95%;
            transform: translate(-100%, -50%);
        }
    }

    &.first {
        background-color: $primary_op_2;

        img {
            border: 5px solid $primary;
        }
    }

    &.second {
        background-color: $secondary_op_2;

        img {
            border: 5px solid $tertiary;
        }
    }

    &.third {
        background-color: $primary_op_2;

        img {
            border: 5px solid $primary;
        }
    }
}

.products-description {
    font-size: 1.3rem;
    line-height: 1.8rem;
    margin: 1.5rem 0;
    text-align: center;

    a {
        text-decoration: none;
        color: $secondary;
        font-weight: bold;
    }
}
/* =============== FIN DE PRODUCTOS ORBS =============== */

/* =============== RESEÑAS =============== */
.reviews {
    display: flex;
    flex-direction: column;

    p {
        margin-top: 0;
    }
}

.review {
    font-family: $quote_font;
    font-style: italic;
    font-size: 1.1rem;
    line-height: 1.6rem;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 1.5rem 1.5rem 1.5rem 0rem;

    blockquote {
        display: flex;
        flex-direction: row;
        margin: 1rem 0rem 1rem 0rem;

        &::before {
            content: '“';
            color: $primary;
            margin-right: 1rem;
            font-size: 5rem;
            font-weight: 600;
        }

        &::after {
            content: '”';
            color: $secondary;
            margin-left: -1rem;
            font-size: 5rem;
            font-weight: 600;
        }
    }

    p {
        margin: 0 0.5rem 0 0;
    }

    a {
        font-style: normal;
        color: $secondary;
        text-decoration: none;
        width: max-content;
        max-width: 90%;
    }

    &:nth-of-type(even) {
        align-self: flex-end;
        margin-left: 3rem;
        background-color: $primary_op_1;
        border-radius: 20px 20px 0px 20px;

        a {
            text-align: right;
            margin-right: 2rem;
            align-self: end;
        }
    }

    &:nth-of-type(odd) {
        align-self: flex-start;
        margin-right: 3rem;
        background-color: $secondary_op_1;
        border-radius: 20px 20px 20px 0px;

        a {
            text-align: left;
            margin-left: 2rem;
        }
    }
}
/* =============== FIN DE RESEÑAS =============== */


/* =============== FOOTER =============== */
.footer {
    margin-top: auto;
    width: 100%;
    bottom: 0;
    left: 0;
    background: linear-gradient(
        to bottom,
        #ffffff,
        #FFFFFF 3%,
        $primary_op_2 20%,
        $primary_op_2 100%
    );
}

.footer-title {
    /* RESPONSIVE FOOTER DIVISION */
    display: flex;
    max-width: 100%;
    flex-direction: row;
    justify-content: center;

    img {
        max-width: 100%;
        object-fit: fill;

        &:nth-of-type(1) {
            display: none;
        }
        &:nth-of-type(2) {
            display: none;
        }
    }

    @include tablet {
        img {
            max-width: 50%;
            flex: 1 1 55vw;
            margin-right: -3.75%;

            &:nth-of-type(1) {
                display: none;
            }
            &:nth-of-type(2) {
                margin-left: -4.5%;
                display: block;
            }
        }
    }

    @include desktop {
        img {
            max-width: 33%;
            flex: 1 1 33vw;
            margin-right: -2.5%;

            &:nth-of-type(1) {
                margin-left: -3%;
                display: block;
            }
            &:nth-of-type(2) {
                margin-left: 0;
                display: block;
            }
        }
    }
}

.footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 2rem;

    @include tablet {
        flex-direction: row;
    }
}

.footer-section {
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 1.3rem;
    
    @include tablet {
        flex: 1 1 50%;
        font-size: 1.2rem;
    }
    
    @include desktop {
        flex: 0 1 22%;
        font-size: 1.3rem;
    }
    
    p {
        font-family: $content_font;
        margin-top: 0;
    }
    
    a {
        font-family: $content_font;
        text-decoration: none;
        display: flex;
        color: #000000;
        margin: 0.5rem;
    }

    #texto-redes {
        text-align: center;
    }

    &.legal-links {
        align-items: flex-start;
        padding-left: 10vw;

        @include tablet {
            padding-left: 1rem;
        }

        @include desktop {
            padding-left: 6rem;
        }

        a::before {
            content: '🔹';
            margin-right: 0.3rem;
        }
    }

    &.contact-data {
        align-items: center;
        
        @include tablet {
            padding-left: 1rem;
            align-items: flex-start;
        }

        .site-title-footer {
            align-self: center;
            color: $secondary;
            font-weight: bold;
        }

        .location {
            margin: 0.5rem;
        }

        .location::before {
            content: '📍';
            margin-right: 0.3rem;
        }

        .mail::before {
            content: '📧';
            margin-right: 0.3rem;
        }

        .phone::before {
            content: '📱';
            margin-right: 0.3rem;
        }
    }

    .social {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;´

        svg {
            width: 60px;
            height: 60px;
        }
    }
}

.footer-copyright {
    background-color: $primary;
    padding: 0.5rem;
    text-align: center;
    font-family: $content_font;

    p {
        margin: 0;
        font-size: 1rem;
        line-height: 1.4rem;
    }

    a {
        text-decoration: none;
        font-weight: bold;
        color: #FFFFFF;
    }
}
/* =============== FIN DE FOOTER =============== */

/* =============== GALERÍA DE PRODUCTOS PHOTOSWIPE =============== */

.pswp__top-bar > * {
    font-size: 1.3rem;
}

.pswp__button {
    transform: scale(1.2);
}

.pswp {
    --pswp-icon-color: #1CC4DB!important;
    --pswp-bg: #FFFFFF!important;
}

.pswp__counter {
    font-size: 1.2rem!important;
    font-weight: 600!important;
}

.product-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.gallery-element {
    flex: 0 0 calc(70vw - 0.5rem);
    height: 68vw;
    margin: 0.2rem;
    overflow: hidden;
    transition: box-shadow 0.5s ease-in;
    position: relative;
    user-select: none;
    cursor: pointer;

    @include tablet {
        flex: 0 0 calc(39vw - 1rem);
        height: 38vw;
        margin: 0.3rem;
    }

    @include desktop {
        flex: 0 0 calc(25vw - 1rem);
        height: 24vw;
        margin: 0.3rem;
    }

    img {
        position: relative;
        z-index: -10;
        transition: transform 2s, filter 1.5s ease-in-out;
        transform-origin: center center;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.gallery-first:hover {
        box-shadow: inset 0px 0px 50px 30px $primary_op_3;
    }
    &.gallery-second:hover {
        box-shadow: inset 0px 0px 50px 30px $secondary_op_3;
    }
    &.gallery-third:hover {
        box-shadow: inset 0px 0px 50px 30px $primary_op_3;
    }

    &:hover img {
        filter: brightness(120%);
        transform: scale(1.3);
    }
}
/* =============== FIN DE GALERÍA DE PRODUCTOS PHOTOSWIPE =============== */

/* =============== PORTADA DE MIS NOVIAS =============== */

.mis-novias {
    width: 100%;
    max-width: 650px;
    margin: 1.5rem auto;
    position: relative;
    color: #FFFFFF;
    background-color: black;
    animation-duration: 0.7s;

    .mis-novias-image {
        transition: filter 3s;
    }

    .mis-novias-image-active {
        filter: opacity(0.5);
    }

    .mis-novias-title {
        position: absolute;
        top: 1%;
        left: 5%;
        font-size: 2.1rem;
        display: none;
        animation-duration: 0.3s;
        animation-timing-function: ease-in;

        @include tablet {
            font-size: 3.5rem;
        }
    }

    .mis-novias-text {
        position: absolute;
        top: 20%;
        left: 5%;
        width: 85%;
        display: none;
        font-size: 1.1rem;
        animation-duration: 0.3s;
        animation-timing-function: ease-in;

        @include tablet {
            top: 32%;
            width: 70%;
            line-height: 2rem;
            font-size: 1.5rem;
        }

        @include desktop {
            width: 70%;
            top: 25%;
        }
    }

    .mis-novias-button {
        position: absolute;
        bottom: 5%;
        left: 5%;
        display: none;
        font-size: 1.2rem;
        font-weight: bold;
        padding: 0.5rem 1rem;
        background-color: white;
        color: $secondary;
        border: none;
        box-shadow: 4px 4px 0px 1px $primary;
        transition: all 0.2s ease-in-out;
        animation-duration: 0.3s;
        animation-timing-function: ease-in;

        @include tablet {
            font-size: 1.5rem;
            box-shadow: 8px 8px 0px 1px $primary;
        }

        &:hover {
            cursor: pointer;
            transform: scale(1.1);
        }
    }
}

.novias-gallery {
    margin: 1.5rem auto;
    display: none;
    animation-duration: 1s;
}

@keyframes text_appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
/* =============== FIN DE PORTADA DE MIS NOVIAS =============== */

/* =============== SELECCIÓN DE CITA =============== */
.appointment {
    @extend .product-orb;

    background-color: unset!important;
    cursor: pointer;
    margin-bottom: 3rem;

    img {
        object-fit: cover;
        height: 60vw!important;
        max-height: 320px!important;
        width: 60vw!important;
        max-width: 320px!important;
        filter: opacity(0.65);
    }

    a {
        font-size: 3rem;
        text-shadow:
        -2px -2px 0 $primary,
        2px -2px 0 $primary,
        -2px  2px 0 $primary,
        2px  2px 0 $primary;
        user-select: none;

        @include tablet {
             font-size: 3.6rem;
         }
    }

    p {
        text-align: right;
    }
}

.appointment-section {
    max-width: 1000px;
    margin: 0 auto;
    border: 4px solid $tertiary;
    border-bottom: none;
    border-radius: 20px 20px 0 0;
    padding: 1.5rem;
    overflow: hidden;
    display: none;
    max-height: 0;
    transition: max-height 0.3s ease-out;

    .appointment-explanation {
        position: relative;
        margin: 0 auto;

        .appointment-image {
            position: absolute;
            object-fit: cover;
            height: 100%;
            width: 100%;
            z-index: -9;
            opacity: 0.15;
        }
    }
}

.section-text {
    font-size: 1.3rem;
    line-height: 1.7rem;
}

.element-highlight {
    color: $secondary;
    font-weight: bold;
}

.appointment-option {
    color: $secondary;
    font-size: 1.8rem;
    text-align: center;
    font-weight: bold;
}
/* =============== FIN DE SELECCIÓN DE CITA =============== */

/* =============== FORMULARIO DE CONTACTO =============== */
.contact-form {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 1.5rem 3rem 1.5rem;
    border: 4px solid $tertiary;
    border-top: none;
    border-radius: 0 0 20px 20px;
}

.form-element {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem 0;

    label {
        margin-bottom: 0.3rem;
        font-size: 1.2rem;
        line-height: 1.4rem;
    }

    label[for='fotos'] {
        text-align: center;
    }

    input {
        font-family: $quote_font;
    }

    textarea {
        resize: vertical;
        font-family: $quote_font;
    }

    &.checkbox {
        flex-direction: row-reverse;
        justify-content: start;

        input[type="checkbox"] {
            margin-right: 0.5rem;
        }

        a {
            color: $secondary;
            text-decoration: none;
        }
    }

    &.photos-container {
        display: grid;
        gap: 5px;
        justify-items: center;
        grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
    }

    &.g-recaptcha {
        align-items: center;
    }

    .submit-button {
        width: 80%;
        max-width: 400px;
        margin: 2.5rem auto 0 auto;
        height: 3rem;
        font-family: $content_font;
        font-size: 1.5rem;
        font-weight: 600;
        cursor: pointer;
        background-color: $primary;
        color: #ffffff;
        border: none;
        border-radius: 10px;

        &:hover {
            background-color: $secondary;
            color: #ffffff;
        }

        &.disabled {
            cursor: default;
            background-color: #555555;
            color: #ffffff;
        }
    }

    .uploadcare--widget {
        text-align: center;

        button {
            height: 2.5rem;
            font-family: $content_font;
            font-size: 1.3rem;
            font-weight: 600;
            background-color: $primary;
            cursor: pointer;

            &:hover {
                background-color: $secondary;
                color: #FFFFFF;
            }
        }
    }
}

.uploadcare--progressbar__value {
    background-color: $primary;
}

.uploadcare--progress_type_canvas {
    color: $primary;
}


.uploadcare--tab__content::after {
    content: 'Sube imágenes con un tamaño inferior a 8 MB';
    padding: 1em;
    margin: 1em;
    font-weight: 600;
    color: $secondary;
    border-radius: 5px;
}

.uploadcare--tab__content {
    position: relative;
}
.uploadcare--tab__content::before{
    position: absolute;
    content: 'Espera unos segundos hasta que se muestren las fotos que has seleccionado';
    top: 80%;
    padding: 1em;
    margin: 1em;
    font-weight: 600;
    border-radius: 5px;
}

.uploadcare--widget__text {
    white-space: normal;
}

.uploadcare--widget__file-name {
    color: $secondary;
    word-wrap: normal;
    font-family: $content_font;
    text-decoration: none;
    font-size: 1.2rem;

    &::before {
        content: 'Haz clic aquí para seleccionar imágenes. ';
    }
}
/* =============== FIN DE FORMULARIO DE CONTACTO =============== */

/* =============== ESTILOS DE PAGINAS LEGALES =============== */
.legal-content {

    .legal-title {
        color: $secondary;
    }

    p {
        font-size: 1.1rem;
        line-height: 1.4rem;
    }

    ol {
        padding: 0 1rem;
    }

    li::marker {
        color: $secondary;
        font-size: 1.5rem;
    }
}
/* =============== FIN DE ESTILOS DE PAGINAS LEGALES =============== */

/* =============== BOTON IR ARRIBA =============== */
.go-up-button {
    position: fixed;
    display: none;
    height: 60px;
    width: 60px;
    right: 1rem;
    bottom: 10rem;
    text-align: center;
    background-color: $primary;
    padding: 10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    animation: right-fade-in 0.7s;
    cursor: pointer;
    
    svg {
        width: 44px;
        height: 44px;
    }

    @include desktop {
        height: 70px;
        width: 70px;
    }
}

.go-up-button-active {
    animation: right-fade-in 0.7s;
    display: flex;
    justify-content: center; 
    align-items: center;
    text-align: left;
}
.go-up-button-inactive {
    animation: right-fade-out 0.7s;
}

@keyframes right-fade-in {
    from {
        opacity: 0;
        transform: translateX(200px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}
@keyframes left-fade-in {
    from {
        opacity: 0;
        transform: translateX(200px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}
@keyframes right-fade-out {
    from {
        opacity: 1;
        transform: translateX(0);
    }
    to {
        opacity: 0;
        transform: translateX(200px);
    }
}
/* =============== FIN DE BOTON IR ARRIBA =============== */

/* =============== COOKIE CONSENT =============== */
.freeprivacypolicy-com---nb-simple {
    left: 0!important;
    right: auto!important;
}
/* =============== FIN DE COOKIE CONSENT =============== */
