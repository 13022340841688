// Colours
$dark_transparent_bg: #0000008e;
// $secondary: #1CC4DB;
$secondary: #3ac8db;
$primary_op_1: #1cc5db28;
$primary_op_2: #1cc5db1c;
$primary_op_3: #1cc5db59;
$primary: #86D4DC;
$secondary_op_1: #0a94c22a;
$secondary_op_2: #0a94c223;
$secondary_op_3: #0a94c256;
$tertiary: #C5EAEE;

// Fonts
// @font-face {
//     font-family: 'Adam Script';
//     src: url('fonts/AdamScript/AdamScript.ttf');
// }


// $title_font: 'Quando', serif;
$title_font: 'Dancing Script', ;
$quote_font: 'Marcellus', serif;
// $content_font: 'Aref Ruqaa', serif;
$content_font: 'Bodoni Moda', sans-serif;
